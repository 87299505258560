export interface Tip {
    id: string;
    title: string;
    description: string;
    category: 'daily' | 'lucent' | 'misc' | 'farms';
}

export const tips: Tip[] = [
    // Daily Tips
    {
        id: 'daily-1',
        title: 'Daily Dungeons',
        description: 'Run 3 dungeons daily (900 tokens). Weekly completion yields 51 trait unlock stones.',
        category: 'daily'
    },
    {
        id: 'daily-2',
        title: 'Contract Management',
        description: 'Keep contracts under 60 cap (10/day). Prioritize higher tier contracts and blessed pouch rewards.',
        category: 'daily'
    },
    {
        id: 'daily-3',
        title: 'Mystic Keys',
        description: 'Use purchased mystic keys within 7 days before expiry.',
        category: 'daily'
    },
    {
        id: 'daily-4',
        title: 'Contract Coin Merchant',
        description: '5 mystic keys, Precious Blessing Pouch, 25x Rare Parchment daily.',
        category: 'daily'
    },
    {
        id: 'daily-5',
        title: 'Sundries Merchant',
        description: 'Daily: 10x Egg, 10x Golden Rye, 5x Honey. Weekly: 6x Allied Contract Scroll, 3x Allied Tower Contract Scroll.',
        category: 'daily'
    },
    {
        id: 'daily-6',
        title: 'Guild Merchant',
        description: '10x Rare Recovery Crystals, 20x Mana Potions, 4x Precious Polished Crystal, 3x Purple/10x Blue Material Chests.',
        category: 'daily'
    },

    // Lucent Making Tips
    {
        id: 'lucent-1',
        title: 'Server Arbitrage',
        description: 'Level chars to 40 on full release, buy items to sell on early access (5000+ lucent for efficiency, 750 transfer cost).',
        category: 'lucent'
    },
    {
        id: 'lucent-2',
        title: 'Lithograph Flipping',
        description: 'Purchase lithograph components with lucent for quick profit.',
        category: 'lucent'
    },
    {
        id: 'lucent-3',
        title: 'Early Sales Priority',
        description: 'Sell items early while prices are high, before market saturation reduces values.',
        category: 'lucent'
    },
    {
        id: 'lucent-4',
        title: 'Contract Coins',
        description: 'Save contract coins for T2 weapons chests.',
        category: 'lucent'
    },
    {
        id: 'lucent-5',
        title: 'Purelight Hill Contracts',
        description: 'Best location for farming contracts efficiently.',
        category: 'lucent'
    },

    // Misc Tips
    {
        id: 'misc-1',
        title: 'Weekly Gate of Infinity',
        description: 'Complete for 20 polished crystals and materials needed for Growth stones.',
        category: 'misc'
    },
    {
        id: 'misc-2',
        title: 'Abyssal Contracts',
        description: 'Keep tokens below 20k, farm dungeons with BiS drops or materials for growthstones.',
        category: 'misc'
    },

    // Farming Location Tips
    {
        id: 'farms-1',
        title: 'Wasp & Harvester Area',
        description: 'Use dagger/bow skills for honey & golden rye. Farm only if needed for food/leveling.',
        category: 'farms'
    },
    {
        id: 'farms-2',
        title: 'Freshwater Fishing',
        description: 'Dedicated location for fresh water fish varieties.',
        category: 'farms'
    },
    {
        id: 'farms-3',
        title: 'Rainy Boar Location',
        description: 'Kill boars for chewy meat, chop logs for golden apples (rain only).',
        category: 'farms'
    },
    {
        id: 'farms-4',
        title: 'Mushroom Grounds',
        description: 'Shiitake mushrooms and smaller gatherables for basic food crafting.',
        category: 'farms'
    },
    {
        id: 'farms-5',
        title: 'Terror Bird Territory',
        description: 'Farm bird meat, eggs, and nighttime leaf gatherables for saffron.',
        category: 'farms'
    },
    {
        id: 'farms-6',
        title: 'Flame Enemy Zone',
        description: 'All flame enemies drop curry powder, elites drop rare polished stones.',
        category: 'farms'
    },
    {
        id: 'farms-7',
        title: 'Night Scorpion Area',
        description: 'Scorpion tails at night, alternative golden apple farming location.',
        category: 'farms'
    },
    {
        id: 'farms-8',
        title: 'Beach Resources',
        description: 'Crabs for nippers/salt (rain only), beach mobs for salt, fishing for seafood/fish oil.',
        category: 'farms'
    }
];