import { Tip, tips } from '../data/tips.tsx';
import { Grid, Card, CardContent, Typography, Box, Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import React from 'react';


export const TipsGrid = () => {
    const [category, setCategory] = useState<'daily' | 'lucent' | 'misc' | 'farms'>('daily');

    const filteredTips = tips.filter(tip => tip.category === category);

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={category}
                onChange={(_, newValue) => setCategory(newValue)}
                sx={{
                    mb: 4,
                    '& .MuiTabs-indicator': {
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        height: 3,
                    },
                    '& .MuiTab-root': {
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontFamily: '"Inter", sans-serif',
                        fontSize: '1rem',
                        fontWeight: 500,
                        textTransform: 'none',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                            color: 'rgba(255, 255, 255, 0.9)',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                        '&.Mui-selected': {
                            color: 'rgba(255, 255, 255, 1)',
                        },
                    },
                }}
            >
                <Tab label="Daily Tips" value="daily" />
                <Tab label="Lucent Making" value="lucent" />
                <Tab label="Miscellaneous" value="misc" />
                <Tab label="Farming Spots" value="farms" />
            </Tabs>

            <Grid container spacing={3}>
                {filteredTips.map((tip) => (
                    <Grid item xs={12} sm={6} md={4} key={tip.id}>
                        <Card sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            backdropFilter: 'blur(10px)',
                            border: '1px solid rgba(255, 255, 255, 0.1)',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                transform: 'translateY(-4px)',
                                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                            }
                        }}>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        color: 'white',
                                        fontFamily: '"Inter", sans-serif',
                                        fontWeight: 600,
                                        mb: 2
                                    }}
                                >
                                    {tip.title}
                                </Typography>
                                <Typography 
                                    variant="body2"
                                    sx={{ 
                                        color: 'rgba(255, 255, 255, 0.7)',
                                        fontFamily: '"Inter", sans-serif'
                                    }}
                                >
                                    {tip.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};