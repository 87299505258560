export const enQuestData = [
  {
  
    level: 10,
    timestamp: "00:05:00",
    tasks: [
      "Follow main story quest",
      "Do the crafting quests while in town",
      "Farm events with your guild along the way, should be a couple usually.",
      "Should do the taedels 1st floor when you unlock it.",
      "Ignore purple quests for the most part, can complete later easier."
    ],
    steps:[
      "Get to level 21, this can easily be done in 1 1/2 - 2 hours"
    ]
  },
  {

 
    level: 21,
    timestamp: "01:11:36",
    tasks: [
      "Replenish Leafs",
      "Allocate Skill Points",
      "Craft Cloak",
      "Craft Ring",
      "Craft whatever"
    ],
    steps: [
      "Fly across canyon to the camp under overpass from top ridge(01:13:05)",
      "Obtain waypoint (Monolith Wastelands)",
      "Speak to scared Resistance Soldier (01:15:31)",
      "Return Back",
      "Speak to guild advisor on bridge (01:16:12)",
      "Go up stairs to where you jumped off and speak to Morti Guild Advisor (01:16:28)",
      "Pick up item and continue going up(01:16:31)",
      "Use grapple hook to continue going up and kill baddies inside the door you find",
      "Jump to crematorium area(01:17:49)",
      "Speak to Crematorium Worker near rock hole (right of the exit)",
      "Collect resistance emblems (left side from exit of city)",
      "Complete tasks and return back",
      "Ensure you reach level 23 before returning"
    ]
  },
  {
    level: 25,
    timestamp: "01:33:30",
    steps: [
      "Kill Leonidas",
      "Level up to 26 (01:33:30)",
      "Claim reward",
      "Travel to camp across the canyon",
      "Speak to quarrelsome merchant (01:33:52)",
      "Return to Vienta",
      "Check Ad board for Flame Village quest",
      "Travel to flame village and defeat the chiefs warrior(01:35:47)",
      "Get Abandoned Stonemason Waypoint",
      "Find Golem Crafter (01:39:00)",
      "Kill trapped basilisk and Giant Crystal Scorpion",
      "Can also complete Taedals tower floors here",
      "Return to Vienta (should be level 28)"
    ]
  },
  {
    level: 30,
    timestamp: "01:55:18",
    steps: [
      "Complete 'Heroes of Stonegard' quest",
      "Acquire Sandworm Lair",
      "Speak to Merchant Vanguard (01:55:18)",
      "Fly into closest tornado, wait, and repeat",
      "Complete 'Doll in the Dust' -> 'Secret in the Sand Dust' (finish at level 31)",
      "Speak to Monster Researcher (01:59:36)",
      "Follow video: https://www.twitch.tv/videos/2256098448",
      "Find Mysterious mask (way up top on overpass)",
      "Complete tasks to reach level 32"
    ]
  },
  {
    level: 36,
    timestamp: "02:19:03",
    steps: [
      "Obtain Manawastes(02:21:00)",
      "Obtain items to the north of manawastes, the three horn thingys.",
      "Obtain Daybreak Shore",
      "Complete any available blue quests to reach level 38",
      "Kill starlight fireflies at night (find alternative quests if needed)(02:25:30)",
      "Taedal tower floors can be completed here",
    ]
  },
  {
    level: 44,
    timestamp: "2:48:03",
    steps: [
      "Travel to Manawastes (02:48:03)",
      "Obtain ancient script lithographs(02:48:40)",
      "Obtain Akidu Valley Waypoint",
      "Fly down for parkour quest (02:50:00)",
      "Complete Dawnbridge lever quest",
      "Reach Level 45",
      "Begin Level 50 quests"
    ]
  }
];