import React, { useState, useEffect } from 'react';
import { Globe, Calculator, BookOpen, ChevronDown } from 'lucide-react';
import MarketCalculator from './components/MarketCalculator';
import BlueQuestGuide from './components/blue-quest-guide-component.tsx';
import ResourceGrid from './components/resource-grid.tsx';
import { motion } from 'framer-motion';
import { enQuestData } from './data/en-quest-data';
import { ruQuestData } from './data/ru-quest-data';
import { resourceData } from './data/resource-data.js';  // Make sure this is at the top with other imports
import ScrollEffects from './components/scroll-effects-component.tsx';
import NavButton from './components/nav-button.tsx';
import { Container, Box, Button } from '@mui/material'; // Add this import
import { TipsGrid } from './components/tips-grid-component.tsx'; // Add this import
import logo from './logo.svg';

const questData = {
  en: enQuestData,
  ru: ruQuestData
};

const App = () => {
  const [activeTab, setActiveTab] = useState("level10");
  const [completedSteps, setCompletedSteps] = useState({});
  const [language, setLanguage] = useState('en');
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [view, setView] = useState('resources'); // Add this state

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const savedCompletedSteps = localStorage.getItem('completedSteps');
    if (savedCompletedSteps) {
      setCompletedSteps(JSON.parse(savedCompletedSteps));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('completedSteps', JSON.stringify(completedSteps));
  }, [completedSteps]);

  const toggleStepCompletion = (level, stepIndex) => {
    setCompletedSteps(prev => {
      const newCompletedSteps = { ...prev };
      const key = `${level}-${stepIndex}`;
      newCompletedSteps[key] = !newCompletedSteps[key];
      return newCompletedSteps;
    });
  };

  return (
    <ScrollEffects>
      <div className="min-h-screen bg-gradient-to-b from-[#0a0a0a] to-[#1a1a1a]">
        <header className={`fixed w-full backdrop-blur-xl px-4 py-3 z-50 transition-all duration-500 ${
          scrolled ? 'bg-black/40' : 'bg-black/20'
        }`}>
          <motion.div 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: [0.23, 1, 0.32, 1] }}
            className="container mx-auto flex justify-between items-center"
          >
            <motion.div 
              className="flex items-center space-x-3"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.8 }}
            >
              <img src={logo} alt="T&L Helper Logo" className="h-8 w-auto" />
              <h1 className="text-2xl font-medium text-white tracking-tight">T&L Helper</h1>
            </motion.div>

            <motion.div 
              className="flex items-center space-x-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.8 }}
            >
              <NavButton
                icon={<BookOpen className="w-5 h-5" />}
                onClick={() => setIsGuideOpen(true)}
                label={language === 'en' ? 'Quest Guide' : 'Руководство по квестам'}
              />
              
              <NavButton
                icon={<Calculator className="w-5 h-5" />}
                onClick={() => setIsCalculatorOpen(true)}
                label={language === 'en' ? 'Calculator' : 'Калькулятор'}
              />
              
              <div className="relative group">
                <div className="flex items-center space-x-2 px-4 py-2 rounded-full bg-white/10 backdrop-blur-md border border-white/10 hover:bg-white/20 transition-all duration-300">
                  <Globe className="w-5 h-5 text-white" />
                  <select 
                    value={language} 
                    onChange={(e) => setLanguage(e.target.value)}
                    className="bg-transparent text-white border-none focus:outline-none text-sm font-medium cursor-pointer appearance-none pr-6"
                    style={{ WebkitAppearance: 'none' }}
                  >
                    <option value="en" className="text-black">English</option>
                    <option value="ru" className="text-black">Русский</option>
                  </select>
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                    <ChevronDown className="w-4 h-4 text-white transition-transform duration-300 group-hover:rotate-180" />
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </header>

        <main className="pt-24 min-h-screen px-4 py-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: [0.23, 1, 0.32, 1] }}
            className="container mx-auto"
          >
            <motion.h2 
              className="text-4xl md:text-6xl font-medium text-center mb-4 text-white bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.8 }}
            >
              Resources
            </motion.h2>
            <motion.p 
              className="text-xl text-center mb-16 text-gray-400 max-w-3xl mx-auto font-light"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.8 }}
            >
              Everything you need to enhance your Throne & Liberty experience in one place.
            </motion.p>
            <Container>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
                <Button
                    variant={view === 'resources' ? 'contained' : 'outlined'}
                    onClick={() => setView('resources')}
                >
                    Resources
                </Button>
                <Button
                    variant={view === 'tips' ? 'contained' : 'outlined'}
                    onClick={() => setView('tips')}
                >
                    Tips
                </Button>
            </Box>
            {view === 'resources' ? <ResourceGrid resources={resourceData} /> : <TipsGrid />}
        </Container>
          </motion.div>
        </main>

        <MarketCalculator isOpen={isCalculatorOpen} onClose={() => setIsCalculatorOpen(false)} />
        <BlueQuestGuide 
          isOpen={isGuideOpen} 
          onClose={() => setIsGuideOpen(false)} 
          language={language}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          completedSteps={completedSteps}
          toggleStepCompletion={toggleStepCompletion}
          questData={questData}
        />
      </div>
    </ScrollEffects>
  );
};

export default App;