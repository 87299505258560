export const ruQuestData = [
  {
    level: 10,
    timestamp: "00:05:00",
    tasks: [
      "Следуйте основному квесту",
      "Выполните квесты на крафт, находясь в городе",
      "Участвуйте в событиях с вашей гильдией по пути, обычно должно быть пару.",
      "Следует выполнить 1-й этаж Тедалов, когда вы его откроете.",
      "Игнорируйте фиолетовые квесты, их можно завершить позже."
    ],
    steps: [
      "Достигните 21 уровня, это можно сделать за 1,5 - 2 часа"
    ]
  },
  {
    level: 21,
    timestamp: "01:11:36",
    tasks: [
      "Пополните листья",
      "Распределите очки навыков",
      "Сделайте плащ",
      "Сделайте кольцо",
      "Сделайте что угодно"
    ],
    steps: [
      "Переправьтесь через каньон к лагерю под эстакадой с верхнего хребта",
      "Получите контрольную точку (Монолиты Пустоши)",
      "Поговорите с напуганным солдатом Сопротивления (01:15:31)",
      "Вернитесь назад",
      "Поговорите с советником гильдии на мосту (01:16:12)",
      "Поднимитесь по лестнице туда, где вы прыгали, и поговорите с советником гильдии Морти (01:16:28)",
      "Подберите предмет и продолжайте подниматься (01:16:31)",
      "Используйте крюк для подъема и убейте врагов внутри двери, которую вы найдете",
      "Прыгните в зону крематория(01:17:49)",
      "Поговорите с работником крематория возле каменной дыры (справа от выхода)",
      "Соберите эмблемы Сопротивления (слева от выхода из города)",
      "Выполните задания и вернитесь назад",
      "Убедитесь, что вы достигли 23 уровня перед возвращением"
    ]
  },
  {
    level: 25,
    timestamp: "01:33:30",
    steps: [
      "Убейте Леонида",
      "Повышение уровня до 26 (01:33:30)",
      "Получите награду",
      "Переместитесь в лагерь через каньон",
      "Поговорите с сварливым торговцем (01:33:52)",
      "Вернитесь в Вьенту",
      "Проверьте доску объявлений на квест деревни Пламени",
      "Переместитесь в деревню Пламени и победите воина вождя (01:35:47)",
      "Получите контрольную точку Заброшенного каменщика",
      "Найдите Создателя големов (01:39:00)",
      "Убейте пойманного базилиска и гигантского кристального скорпиона",
      "Также можно завершить этажи башни Тедалов здесь",
      "Вернитесь в Вьенту (должны быть 28 уровень)"
    ]
  },
  {
    level: 30,
    timestamp: "01:55:18",
    steps: [
      "Завершите квест 'Герои Стоунгарда'",
      "Получите логово песчаного червя",
      "Поговорите с торговцем Передовой (01:55:18)",
      "Улетите в ближайший торнадо, подождите и повторите",
      "Завершите 'Куклу в Пыли' -> 'Секрет в Песчаной Пыли' (закончите на 31 уровне)",
      "Поговорите с Исследователем монстров (01:59:36)",
      "Следуйте видео: https://www.twitch.tv/videos/2256098448",
      "Найдите загадочную маску (вверху на эстакаде)",
      "Выполните задания, чтобы достичь 32 уровня"
    ]
  },
  {
    level: 36,
    timestamp: "02:19:03",
    steps: [
      "Получите Манавейст (02:21:00)",
      "Получите Утренний берег",
      "Завершите любые доступные синие квесты, чтобы достичь 38 уровня",
      "Убейте светящихся светлячков ночью (найдите альтернативные квесты, если нужно) (02:25:30)",
      "Этажи башни Тедалов можно завершить здесь"
    ]
  },
  {
    level: 44,
    timestamp: "2:48:03",
    steps: [
      "Переместитесь в Манавейст (02:48:03)",
      "Получите литографии древних скриптов (02:48:40)",
      "Получите контрольную точку Долины Акаиду",
      "Спуститесь для паркура (02:50:00)",
      "Завершите квест с рычагом на Рассветном мосту",
      "Достигните 45 уровня",
      "Начните квесты на 50 уровне"
    ]
  }
];
