import React from 'react';
import { motion } from 'framer-motion';

interface Resource {
  id: number;
  title: string;
  description: string;
  link?: string;
  buttonText: string;
}

const ResourceCard: React.FC<Resource> = ({ title, description, link, buttonText }) => {
  return (
<motion.div 
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  viewport={{ once: true }}
  transition={{ duration: 0.6, ease: [0.23, 1, 0.32, 1] }}
  className="group relative flex flex-col h-full bg-white/10 backdrop-blur-xl rounded-3xl p-8 overflow-hidden border border-white/10"
>
<div className="absolute inset-0 bg-gradient-to-br from-white/[0.07] to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
      
      <motion.h3 
        className="text-2xl font-medium text-white mb-4"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        {title}
      </motion.h3>
      
      <motion.p 
        className="text-gray-300 mb-8 text-lg leading-relaxed"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        {description}
      </motion.p>

      {link && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="relative mt-auto"
        >
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center w-full px-6 py-4 rounded-2xl bg-white/10 text-white font-medium 
                     hover:bg-white/20 transition-all duration-500 backdrop-blur-sm
                     border border-white/10 hover:border-white/20
                     transform hover:scale-[1.02] active:scale-[0.98]"
          >
            {buttonText}
          </a>
        </motion.div>
      )}
    </motion.div>
  );
};

const ResourceGrid: React.FC<{ resources: Resource[] }> = ({ resources }) => {
  return (
    <div className="relative px-4 py-8">
      <div className="absolute inset-0 bg-gradient-to-b from-[#1a1a1a] to-[#0a0a0a] -z-10" />
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 auto-rows-fr">
          {resources.map((resource) => (
            <ResourceCard key={resource.id} {...resource} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResourceGrid;