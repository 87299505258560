import React from 'react';
import { motion } from 'framer-motion';

interface NavButtonProps {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
}

const NavButton: React.FC<NavButtonProps> = ({ icon, label, onClick }) => {
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className="group flex items-center space-x-2 px-4 py-2 rounded-full 
                 bg-white/10 backdrop-blur-md border border-white/10 
                 hover:bg-white/20 transition-all duration-300"
    >
      <motion.span 
        initial={{ scale: 1 }}
        className="text-white"
      >
        {icon}
      </motion.span>
      <span className="text-white text-sm font-medium">{label}</span>
    </motion.button>
  );
};

export default NavButton;