import React, { useState, useEffect } from 'react';
import { X, Info, Plus, Trash2 } from 'lucide-react';

const CalculationStep = ({ label, value, formula, explanation }) => (
  <div className="mb-2">
    <div className="flex justify-between items-center">
      <span className="font-medium">{label}:</span>
      <span className="font-semibold">{value}</span>
    </div>
    <div className="text-xs text-gray-600 mt-1">
      <span className="font-medium">Formula:</span> {formula}
    </div>
    <div className="text-xs text-gray-600">
      <span className="font-medium">Explanation:</span> {explanation}
    </div>
  </div>
);

const MarketCalculator = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState('simple');
  const [itemCost, setItemCost] = useState(398);
  const [desiredProfit, setDesiredProfit] = useState(10);
  const [marketPrice, setMarketPrice] = useState('');
  const [trades, setTrades] = useState(() => {
    const savedTrades = localStorage.getItem('trades');
    return savedTrades ? JSON.parse(savedTrades) : [];
  });
  const [components, setComponents] = useState([
    { name: '', cost: '', quantity: 1 }
  ]);
  const [calculatedValues, setCalculatedValues] = useState({
    priceAfterTax: 0,
    requiredSellingPrice: 0,
    amountAfterSelling: 0,
    profitInValue: 0,
    taxPaid: 0,
    profitMargin: 0
  });

  useEffect(() => {
    localStorage.setItem('trades', JSON.stringify(trades));
  }, [trades]);

  const calculateSimple = () => {
    const tax = 0.22; // 5% tax
    const requiredSellingPrice = Math.ceil(itemCost / (1 - tax) * (1 + desiredProfit / 100));
    const priceAfterTax = Math.floor(requiredSellingPrice * (1 - tax));
    const taxPaid = requiredSellingPrice - priceAfterTax;
    const profitInValue = priceAfterTax - itemCost;
    const profitMargin = ((priceAfterTax - itemCost) / itemCost * 100).toFixed(2);

    setCalculatedValues({
      priceAfterTax,
      requiredSellingPrice,
      amountAfterSelling: priceAfterTax,
      profitInValue,
      taxPaid,
      profitMargin
    });
  };

  const calculateCrafting = () => {
    const tax = 0.22; // 5% tax
    const totalCost = components.reduce((sum, component) => {
      return sum + (Number(component.cost) || 0) * (Number(component.quantity) || 0);
    }, 0);

    const priceAfterTax = Math.floor(Number(marketPrice) * (1 - tax));
    const taxPaid = Number(marketPrice) - priceAfterTax;
    const profitInValue = priceAfterTax - totalCost;
    const profitMargin = ((profitInValue / totalCost) * 100).toFixed(2);

    setCalculatedValues({
      priceAfterTax,
      requiredSellingPrice: Number(marketPrice),
      amountAfterSelling: priceAfterTax,
      profitInValue,
      taxPaid,
      profitMargin
    });
  };

  useEffect(() => {
    if (activeTab === 'simple') {
      calculateSimple();
    }
  }, [itemCost, desiredProfit]);

  useEffect(() => {
    if (activeTab === 'crafting' && marketPrice) {
      calculateCrafting();
    }
  }, [marketPrice, components]);

  const handleComponentChange = (index, field, value) => {
    const newComponents = [...components];
    newComponents[index][field] = value;
    setComponents(newComponents);
  };

  const addComponent = () => {
    setComponents([...components, { name: '', cost: '', quantity: 1 }]);
  };

  const removeComponent = (index) => {
    setComponents(components.filter((_, i) => i !== index));
  };

  const saveTrade = () => {
    const newTrade = {
      id: Date.now().toString(),
      type: activeTab,
      date: new Date().toISOString(),
      ...(activeTab === 'simple' 
        ? {
            itemCost,
            desiredProfit,
            requiredSellingPrice: calculatedValues.requiredSellingPrice,
            profitValue: calculatedValues.profitInValue,
          }
        : {
            components,
            marketPrice: Number(marketPrice),
            totalCost: components.reduce((sum, component) => 
              sum + (Number(component.cost) || 0) * (Number(component.quantity) || 0), 0),
            profitMargin: calculatedValues.profitMargin,
          }
      )
    };
    setTrades(prev => [newTrade, ...prev]);
  };

  const deleteTrade = (tradeId) => {
    setTrades(prev => prev.filter(trade => trade.id !== tradeId));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Market Calculator</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <div className="mb-4">
          <div className="flex space-x-2 border-b">
            <button
              className={`px-4 py-2 ${activeTab === 'simple' ? 'border-b-2 border-blue-500' : ''}`}
              onClick={() => setActiveTab('simple')}
            >
              Simple Calculator
            </button>
            <button
              className={`px-4 py-2 ${activeTab === 'crafting' ? 'border-b-2 border-blue-500' : ''}`}
              onClick={() => setActiveTab('crafting')}
            >
              Crafting Calculator
            </button>
            <button
              className={`px-4 py-2 ${activeTab === 'trades' ? 'border-b-2 border-blue-500' : ''}`}
              onClick={() => setActiveTab('trades')}
            >
              Trade History
            </button>
          </div>
        </div>

        {activeTab === 'trades' ? (
          // Keep existing trade history section
          <div className="space-y-4">
            <h3 className="font-semibold text-lg">Trade History</h3>
            <div className="space-y-2">
              {trades.map((trade) => (
                <div key={trade.id} className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex justify-between items-start">
                    <div>
                      <span className="text-sm text-gray-500">
                        {new Date(trade.date).toLocaleDateString()}
                      </span>
                      <div className="mt-1">
                        {trade.type === 'simple' ? (
                          <>
                            <p>Item Cost: {trade.itemCost}</p>
                            <p>Profit: {trade.profitValue}</p>
                            <p>Required Selling Price: {trade.requiredSellingPrice}</p>
                          </>
                        ) : (
                          <>
                            <p>Market Price: {trade.marketPrice}</p>
                            <p>Total Cost: {trade.totalCost}</p>
                            <p>Profit Margin: {trade.profitMargin}%</p>
                          </>
                        )}
                      </div>
                    </div>
                    <button
                      onClick={() => deleteTrade(trade.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : activeTab === 'simple' ? (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Item Cost</label>
              <input
                type="number"
                value={itemCost}
                onChange={(e) => setItemCost(Number(e.target.value))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Desired Profit (%)</label>
              <input
                type="number"
                value={desiredProfit}
                onChange={(e) => setDesiredProfit(Number(e.target.value))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div className="bg-gray-100 p-4 rounded-md">
              <h3 className="font-semibold mb-2">Calculations (including 22% tax):</h3>
              <CalculationStep
                label="Required Selling Price"
                value={calculatedValues.requiredSellingPrice.toLocaleString()}
                formula="Item Cost / (1 - Tax Rate - Desired Profit Rate)"
                explanation="The price you need to set to achieve your desired profit after tax."
              />
              <CalculationStep
                label="Amount You Receive"
                value={calculatedValues.amountAfterSelling.toLocaleString()}
                formula="Required Selling Price * (1 - Tax Rate)"
                explanation="The amount you'll actually receive after the tax is deducted."
              />
              <CalculationStep
                label="Your Profit"
                value={calculatedValues.profitInValue.toLocaleString()}
                formula="Amount You Receive - Item Cost"
                explanation="The profit you'll make on this trade."
              />
              <CalculationStep
                label="Tax Paid"
                value={calculatedValues.taxPaid.toLocaleString()}
                formula="Required Selling Price * Tax Rate"
                explanation="The amount of tax that will be deducted from the sale."
              />
            </div>
            <div className="bg-blue-50 p-4 rounded-md">
              <h3 className="font-semibold mb-2 flex items-center">
                <Info size={18} className="mr-2" />
                Additional Trading Insights:
              </h3>
              <ul className="list-disc pl-5 space-y-2">
                <li>
                  <span className="font-medium">Profit Margin: </span>
                  {calculatedValues.profitMargin}%
                  <p className="text-xs text-gray-600">
                    This is your profit as a percentage of the selling price. Higher is generally better, but consider market competitiveness.
                  </p>
                </li>
                <li>
                  <span className="font-medium">Break-Even Price: </span>
                  {(parseFloat(itemCost) / 0.95).toFixed(2)}
                  <p className="text-xs text-gray-600">
                    This is the minimum price you need to sell at to recover your costs after tax.
                  </p>
                </li>
                <li>
                  <span className="font-medium">Effective Tax Rate: </span>
                  {((parseFloat(calculatedValues.taxPaid) / parseFloat(calculatedValues.requiredSellingPrice)) * 100).toFixed(2)}%
                  <p className="text-xs text-gray-600">
                    This shows how much of your selling price goes to tax. It should always be 22% in this case.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Market Price</label>
              <input
                type="number"
                value={marketPrice}
                onChange={(e) => setMarketPrice(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <h3 className="font-semibold">Components</h3>
                <button
                  onClick={addComponent}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <Plus size={20} className="mr-1 inline" /> Add Component
                </button>
              </div>
              {components.map((component, index) => (
                <div key={index} className="flex space-x-2 mb-2">
                  <input
                    type="text"
                    placeholder="Name"
                    value={component.name}
                    onChange={(e) => handleComponentChange(index, 'name', e.target.value)}
                    className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                  <input
                    type="number"
                    placeholder="Cost"
                    value={component.cost}
                    onChange={(e) => handleComponentChange(index, 'cost', e.target.value)}
                    className="w-24 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                  <input
                    type="number"
                    placeholder="Qty"
                    value={component.quantity}
                    onChange={(e) => handleComponentChange(index, 'quantity', e.target.value)}
                    className="w-20 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                  <button
                    onClick={() => removeComponent(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              ))}
            </div>
            {marketPrice && (
              <div className="bg-gray-100 p-4 rounded-md">
                <h3 className="font-semibold mb-2">Crafting Calculations:</h3>
                <CalculationStep
                  label="Total Cost of Components"
                  value={components.reduce((sum, component) => 
                    sum + (Number(component.cost) || 0) * (Number(component.quantity) || 0), 0).toLocaleString()}
                  formula="Sum of (Component Cost × Quantity)"
                  explanation="The total cost of all materials needed"
                />
                <CalculationStep
                  label="Amount After Tax"
                  value={calculatedValues.priceAfterTax.toLocaleString()}
                  formula="Market Price × (1 - 0.22)"
                  explanation="What you'll receive after the 22% market tax"
                />
                <CalculationStep
                  label="Profit"
                  value={calculatedValues.profitInValue.toLocaleString()}
                  formula="Amount After Tax - Total Cost"
                  explanation="Your net profit from crafting and selling"
                />
                <CalculationStep
                  label="Profit Margin"
                  value={`${calculatedValues.profitMargin}%`}
                  formula="(Profit ÷ Market Price) × 100"
                  explanation="Your profit as a percentage of the market price"
                />
              </div>
            )}
          </div>
        )}

        {activeTab !== 'trades' && (
          <button
            onClick={saveTrade}
            className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            Save Trade
          </button>
        )}

        <div className="mt-4 text-xs text-gray-500 text-center">
          All calculations include 22% tax. Prices are rounded to 2 decimal places.
        </div>
      </div>
    </div>
  );
};

export default MarketCalculator;

