import React from 'react';
import { CheckCircle2, XCircle, X } from 'lucide-react';

const TWITCH_VIDEO_URL = 'https://www.twitch.tv/videos/2256098448';

const BlueQuestGuide = ({ isOpen, onClose, language, activeTab, setActiveTab, completedSteps, toggleStepCompletion, questData }) => {
  if (!isOpen) return null;

  const getTimestampUrl = (timestamp) => {
    const [hours, minutes, seconds] = timestamp.split(':').map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return `${TWITCH_VIDEO_URL}?t=${totalSeconds}s`;
  };

  const renderStep = (step, index, level) => {
    const timestampRegex = /\((\d{2}:\d{2}:\d{2})\)/;
    const match = step.match(timestampRegex);
    
    if (match) {
      const [fullMatch, timestamp] = match;
      const parts = step.split(fullMatch);
      return (
        <>
          {parts[0]}
          <a 
            href={getTimestampUrl(timestamp)} 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            {fullMatch}
          </a>
          {parts[1]}
        </>
      );
    }
    
    return step;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-4xl max-h-[90vh] overflow-y-auto transform transition-all duration-300 ease-in-out"
           style={{
             opacity: isOpen ? 1 : 0,
             transform: isOpen ? 'scale(1)' : 'scale(0.9)'
           }}>
        <div className="flex justify-between items-center mb-5">
          <h2 className="text-2xl font-bold">{language === 'en' ? 'T&L Blue Quest Guide' : 'Руководство по квестам T&L Blue'}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="flex mb-5 overflow-x-auto">
          {questData[language].map((quest, index) => (
            <button
              key={index}
              className={`px-4 py-2 mr-2 rounded-md ${activeTab === `level${quest.level}` ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              onClick={() => setActiveTab(`level${quest.level}`)}
            >
              {language === 'en' ? `Level ${quest.level}` : `Уровень ${quest.level}`}
            </button>
          ))}
        </div>
        {questData[language].map((quest, index) => (
          <div key={index} className={`card p-4 ${activeTab === `level${quest.level}` ? '' : 'hidden'}`}>
            <h2 className="text-xl font-semibold mb-2">
              {language === 'en' ? `Level ${quest.level}` : `Уровень ${quest.level}`}
              {quest.timestamp && (
                <a href={getTimestampUrl(quest.timestamp)} target="_blank" rel="noopener noreferrer" className="badge ml-2 hover:bg-blue-100">
                  {quest.timestamp}
                </a>
              )}
            </h2>
            {quest.tasks && (
              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">{language === 'en' ? 'Preparation:' : 'Подготовка:'}</h3>
                <ul className="list-disc pl-5">
                  {quest.tasks.map((task, taskIndex) => (
                    <li key={taskIndex} className="mb-1">{task}</li>
                  ))}
                </ul>
              </div>
            )}
            <h3 className="text-lg font-semibold mb-2">{language === 'en' ? 'Quest Steps:' : 'Шаги квеста:'}</h3>
            <div className="scroll-area h-[300px] overflow-y-auto pr-4">
              <ol className="list-decimal pl-5">
                {quest.steps.map((step, stepIndex) => {
                  const isCompleted = completedSteps[`${quest.level}-${stepIndex}`];
                  return (
                    <li key={stepIndex} className="mb-2 flex items-start">
                      <button
                        onClick={() => toggleStepCompletion(quest.level, stepIndex)}
                        className={`mr-2 p-1 rounded-full flex-shrink-0 ${isCompleted ? 'bg-green-100' : 'bg-red-100'}`}
                      >
                        {isCompleted ? (
                          <CheckCircle2 className="h-4 w-4 text-green-500" />
                        ) : (
                          <XCircle className="h-4 w-4 text-red-500" />
                        )}
                      </button>
                      <span>{renderStep(step, stepIndex, quest.level)}</span>
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlueQuestGuide;
