export const resourceData = [
  {
    id: 1,
    title: "Server Status",
    description: "Check the current status of T&L game servers.",
    link: "https://x.com/TLGameStatus",
    buttonText: "View Status"
  },
  {
    id: 2,
    title: "Throne Codex",
    description: "Search and explore T&L's vast item database, mobs, and more.",
    link: "https://tlcodex.com/en/#",
    buttonText: "Browse Database"
  },
  {
    id: 3,
    title: "Character Classes",
    description: "Learn about different character classes and their skills.",
    link: "https://questlog.gg/throne-and-liberty/en",
    buttonText: "Explore Classes"
  },
  {
    id: 4,
    title: "Interactive Map",
    description: "Comprehensive interactive map with resource locations, NPCs, and more.",
    link: "https://throne-and-liberty.interactivemap.app/?map=1",
    buttonText: "Open Map"
  },
];