import React, { useEffect, useState } from 'react';

const ScrollEffects = ({ children }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }

      // Add 'visible' class to elements with 'scroll-animate' class when they're in view
      const scrollAnimates = document.querySelectorAll('.scroll-animate');
      scrollAnimates.forEach((el) => {
        const rect = el.getBoundingClientRect();
        const isVisible = (rect.top <= window.innerHeight * 0.75 && rect.bottom >= 0);
        if (isVisible) {
          el.classList.add('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  return (
    <div className={scrolled ? 'scrolled' : ''}>
      {children}
    </div>
  );
};

export default ScrollEffects;
